import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
    Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Dialog, DialogTitle, DialogContent, DialogActions, FormControl,
    FormControlLabel, Grid, AppBar, Toolbar, IconButton, TablePagination, Switch,
    InputLabel, Select, MenuItem
} from '@mui/material';
import { Edit, DeleteForever, Close, Add, Cancel, Save } from '@mui/icons-material';
import axios from './CustomAxios';
import { UserContext } from './context/UserContext';

import { toast } from 'react-toastify';
import { SearchInput, LoadingLinearProgress, PageTitle, TableSortingElements } from './components';

function Institutions() {
    const [userContext, setUserContext] = useContext(UserContext)

    const [currentInstitution, setCurrentInstitution] = useState({});
    const [sortOrderDesc, setSortOrderDesc] = useState(true);
    const [sortOrderField, setSortOrderField] = useState('id');
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [countAll, setCountAll] = useState(0);
    const [dense, setDense] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);

    const [institutions, setInstitutions] = useState([])

    const handleClickOpen = (i) => {

        var tempInstitution = Object.assign({}, institutions[i]);
        for (const property in tempInstitution) {
            if (tempInstitution[property] === null)
                tempInstitution[property] = '';
        }
        setCurrentInstitution(tempInstitution);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleSave = (keepOpen) => {
        var body = Object.assign({}, currentInstitution);
        if (body.name)
            if (currentInstitution.id) {
                return axios(userContext.token, setUserContext).put(process.env.REACT_APP_API_ENDPOINT + 'institutions/' + currentInstitution.id, body)
                    .then(res => {
                        getAllInstitutions();
                    })
                    .then(() => {
                        if (!keepOpen)
                            setOpen(false);
                    });
            }
            else {
                return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'institutions', body)
                    .then(res => {
                        getAllInstitutions();
                    })
                    .then(() => {
                        if (!keepOpen) {
                            setOpen(false);
                        }
                    });
            }
    };

    const handleDelete = () => {
        return axios(userContext.token, setUserContext).delete(process.env.REACT_APP_API_ENDPOINT + 'institutions/' + currentInstitution.id)
            .then(res => {
                getAllInstitutions();
                setDeleteOpen(false);
                setOpen(false);
            });
    };

    const handleChangeSearchKeyword = (value => {
        setPage(0);
        setSearchKeyword(value);
    });

    const handleCreate = () => {
        setCurrentInstitution(
            {
            }
        );
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSortOrderField = (event) => {
        setSortOrderField(event.target.value);
    }

    const isRequiredFieldInvalid = (field) => {
        if (field === '' || field === undefined || field === null)
            return true;
        return false;
    };

    const isInstitutionInvalid = (institution) => {
        if (isRequiredFieldInvalid(institution.name))
            return true;
        return false;
    };

    const modifyCurrentInstitution = (e, isNumber) => {
        var tempInstitution = Object.assign({}, currentInstitution);
        if (isNumber)
            tempInstitution[e.target.name] = parseInt(e.target.value);
        else
            tempInstitution[e.target.name] = e.target.value;
        setCurrentInstitution(tempInstitution);
    };

    const getAllInstitutions = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        setLoading(true);
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'institutions?param=' + encodeURIComponent(JSON.stringify({
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            order: [
                [sortOrderField, sortOrderDesc ? 'DESC' : 'ASC']
            ],
            searchKeyword: searchKeyword !== '' ? searchKeyword.trim() : undefined
        })))
            .then(res => {
                setInstitutions(res.data.rows);
                setCountAll(res.data.count);
                setLoading(false);
            });
    }, [page, rowsPerPage, searchKeyword, sortOrderDesc, sortOrderField, userContext.token, setUserContext]);

    useEffect(() => {
        getAllInstitutions();
    }, [getAllInstitutions]);

    return (
        <>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
            >
                <DialogTitle>
                    Oled kindel, et soovid kustutada asutust {currentInstitution.id}?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>Ei</Button>
                    <Button
                        autoFocus
                        onClick={() => toast.promise(
                            handleDelete(),
                            {
                                pending: 'Valmistun kustutama',
                                success: 'Kustutatud',
                                error: 'Kustutamisel tekkis tõrge'
                            }
                        )}
                    >Jah</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid item container direction='row' justifyContent='space-between' xs={12}>
                            <Grid item xs={1}>
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={handleClose}
                                    aria-label='close'
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                            <Grid item container direction='row' xs={11} justifyContent='flex-end' spacing={4} alignItems='center'>
                                <Grid item>
                                    <Button
                                        onClick={() => setDeleteOpen(true)}
                                        aria-label='delete'
                                        color='error'
                                        variant='contained'
                                    >
                                        <DeleteForever />&nbsp;Kustuta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleClose}
                                        aria-label='close'
                                        variant='contained'
                                        color='gray'
                                    >
                                        <Cancel />&nbsp;Katkesta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => toast.promise(
                                            handleSave(),
                                            {
                                                pending: 'Salvestan',
                                                success: 'Salvestatud',
                                                error: 'Salvestamisel tekkis tõrge'
                                            }
                                        )}
                                        aria-label='save'
                                        variant='contained'
                                        disabled={isInstitutionInvalid(currentInstitution)}
                                        color='success'
                                    >
                                        <Save />&nbsp;Salvesta
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogTitle>
                    {currentInstitution.id ? 'Asutus ' + currentInstitution.name : 'Uus asutus'}
                </DialogTitle>
                <DialogContent>
                    <Grid spacing={8} container direction='row'>
                        <Grid container item xs={6} direction='column'>
                            <Grid container item direction='row' justifyContent='space-between'>
                                <Grid item xs={7}>
                                    <TextField
                                        margin='normal'
                                        name='name'
                                        value={currentInstitution.name}
                                        onChange={modifyCurrentInstitution}
                                        label='Nimi'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>

                                    <FormControl fullWidth
                                        margin='normal'>
                                        <InputLabel>Etiketi mall</InputLabel>
                                        <Select
                                            value={currentInstitution.printTemplate}
                                            name='printTemplate'
                                            label='Etiketi mall'
                                            onChange={modifyCurrentInstitution}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <LoadingLinearProgress loading={loading} />
            <Grid
                container
                spacing={4}
                style={{ marginTop: 0, marginBottom: '10px' }}
            >
                <PageTitle title='Asutused' />
                <TableSortingElements 
                    sortOrderDesc={sortOrderDesc} 
                    setSortOrderDesc={setSortOrderDesc} 
                    sortOrderField={sortOrderField} 
                    handleChangeSortOrderField={handleChangeSortOrderField} 
                    />
                <Grid item xs={4}>
                    <SearchInput event={handleChangeSearchKeyword} />
                </Grid>
                <Grid item xs={1.5}>
                    <FormControlLabel
                        style={{ height: '100%' }}
                        control={
                            <Switch checked={dense} onChange={handleChangeDense} />
                        }
                        label='Kitsas vaade'
                    />
                </Grid>
                <Grid container item xs={1.5} justifyContent='flex-end'>
                    <Button onClick={handleCreate}><Add />&nbsp;Lisa uus</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align='right'>Asutus</TableCell>
                            <TableCell align='right'>Etiketi mall</TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            institutions.map((institution, i) => (
                                <TableRow
                                    key={institution.id}
                                    className='tableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component='th' scope='row'>{institution.id}</TableCell>
                                    <TableCell align='right'>{institution.name}</TableCell>
                                    <TableCell align='right'>{institution.printTemplate}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            edge='start'
                                            color='inherit'
                                            onClick={() => handleClickOpen(i)}
                                            aria-label='open'
                                            className='treatment-action-button'
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={countAll}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default Institutions;