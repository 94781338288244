import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Grid, FormControl, InputLabel, Select, MenuItem, IconButton } from "@mui/material";

function TableSortingElements({ sortOrderDesc, setSortOrderDesc, sortOrderField, handleChangeSortOrderField }) {
    return (
        <>
            <Grid alignContent='center' item container xs={0.5}>
                {
                    sortOrderDesc ?
                        <IconButton
                            onClick={() => setSortOrderDesc(!sortOrderDesc)}
                        >
                            <ArrowDownwardIcon />
                        </IconButton>
                        :
                        <IconButton
                            onClick={() => setSortOrderDesc(!sortOrderDesc)}
                        >
                            <ArrowUpwardIcon />
                        </IconButton>
                }
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel>Sorteeritav väli</InputLabel>
                    <Select
                        value={sortOrderField}
                        label='Sorteeritav väli'
                        onChange={handleChangeSortOrderField}
                    >
                        <MenuItem value='id'>ID</MenuItem>
                        <MenuItem value='createdAt'>Loodud</MenuItem>
                        <MenuItem value='updatedAt'>Muudetud</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </>
    );
}

export default TableSortingElements;