import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';

export default function Footer() {
    const [versionIsVisible, setVersionIsVisible] = useState(false);
    return (
        <Paper elevation={4} id="footer">
            <Grid container direction="row" style={{"height": "100%"}} alignContent="center">
                <Grid item xs={1} />
                <Grid item xs={10} textAlign="center"><Typography onClick={() => setVersionIsVisible(!versionIsVisible)} variant="subtitle2">Copyright &copy; SaarIS 2024 {versionIsVisible && <Typography variant="subtitle2" className="version-string">{process.env.REACT_APP_VERSION}</Typography>}</Typography></Grid>
            </Grid>
        </Paper>
    );
}
