import { useCallback, useContext, useEffect } from "react";
import { UserContext } from "./context/UserContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Login from "./Login";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Treatments from "./Treatments";
import Patients from "./Patients";
import Drugs from "./Drugs";
import Institutions from "./Institutions";
import Interfaces from "./Interfaces";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './App.css';
import { ToastContainer } from 'react-toastify';
import Reporting from "./Reporting";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Users from "./Users";
import { jwtDecode } from "jwt-decode";

function App() {
  const [, setUserContext] = useContext(UserContext)


  const verifyUserCallback = useCallback(verifyUser, [setUserContext]);

  useEffect(() => {
    verifyUserCallback(setUserContext);
  }, [verifyUserCallback, setUserContext]);

  const theme = createTheme({
    palette: {
      gray: {
        main: '#dddddd',
        contrastText: '#000000'
      }
    },
  });

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SaarIS{(process.env.REACT_APP_ENVIRONMENT !== 'production') ? ' - ' + process.env.REACT_APP_ENVIRONMENT : ''}</title>
        <link rel="canonical" href={"https://" + window.location.host} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
        <Router>
          <Routes>
            <Route path="/" element={<Page />} >
              <Route index element={<Login />} />
              <Route path="/raviskeemid" element={<Treatments />} />
              <Route path="/patsiendid" element={<Patients />} />
              <Route path="/ravimid" element={<Drugs />} />
              <Route path="/asutused" element={<Institutions />} />
              <Route path="/raport" element={<Reporting />} />
              <Route path="/liidesed" element={<Interfaces />} />
              <Route path="/kasutajad" element={<Users />} />
              <Route path="*" element={<Login />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

function Page() {
  return (
    <div id="page-container">
      <Header />
      <div id="content-wrap">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Outlet />
        </LocalizationProvider>
      </div>
      <Footer />
    </div>
  );
};

function verifyUser(setUserContext, initiateAnother = true) {
  if (initiateAnother)
    setTimeout(() => verifyUser(setUserContext), 15 * 60 * 1000);
  return fetch(process.env.REACT_APP_API_ENDPOINT + "refreshToken", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  }).then(async response => {
    if (response.ok) {
      const data = await response.json();
      const decoded = jwtDecode(data.token);
      setUserContext(oldValues => {
        return { ...oldValues, token: data.token, ...decoded };
      });
      return data.token;
    } else {
      setUserContext(oldValues => {
        return { ...oldValues, token: null };
      });
      return undefined;
    }
  }).catch(error => {
    console.log(error);
    setUserContext(oldValues => {
      return { ...oldValues, token: null };
    });
    return undefined;
  });
};


export { verifyUser };

export default App;
