import { useContext, useEffect, useState } from "react";
import {
    MenuItem, Button, Avatar, Container, Menu, Typography, IconButton, Toolbar, Box, AppBar
} from '@mui/material';
import { UserContext } from "./context/UserContext";
import { useNavigate, Link, useLocation } from "react-router-dom";

const Header = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);

    const [userContext, setUserContext] = useContext(UserContext);
    var navigate = useNavigate();
    var location = useLocation();

    useEffect(() => {
        if (userContext !== undefined && userContext !== null) {
            if (userContext.token === undefined || userContext.token === null) {
                if (!location.pathname.includes('/login'))
                    navigate('/login?return=' + location.pathname);

            }
        }
    }, [userContext, location.pathname, navigate]);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const logOut = () => {
        handleCloseUserMenu();
        fetch(process.env.REACT_APP_API_ENDPOINT + "logout", {
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userContext.token}`,
            },
        }).then(async response => {
            setUserContext(oldValues => {
                return { ...oldValues, token: null }
            })
            window.localStorage.setItem("logout", Date.now());
            navigate('/');
        });
    };

    return (
        <AppBar color="default" position="static">
            <Container maxWidth="auto">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { md: 'flex' } }}
                    >
                        {process.env.REACT_APP_ENVIRONMENT_NAME} SaarIS
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
                        {
                            userContext.token &&
                            <>
                                <Button
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                    component={Link}
                                    className="menu-link" to="/raviskeemid"
                                >
                                    RAVISKEEMID
                                </Button>
                                <Button
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                    component={Link}
                                    className="menu-link" to="/patsiendid"
                                >
                                    PATSIENDID
                                </Button>
                                <Button
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                    component={Link}
                                    className="menu-link" to="/ravimid"
                                >
                                    RAVIMID
                                </Button>
                                <Button
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                    component={Link}
                                    className="menu-link" to="/asutused"
                                >
                                    ASUTUSED
                                </Button>
                                <Button
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                    component={Link}
                                    className="menu-link" to="/raport"
                                >
                                    RAPORTID
                                </Button>
                                <Button
                                    sx={{ my: 2, color: 'black', display: 'block' }}
                                    component={Link}
                                    className="menu-link" to="/liidesed"
                                >
                                    LIIDESED
                                </Button>
                                {userContext.role === 'admin' &&
                                    <Button
                                        sx={{ my: 2, color: 'black', display: 'block' }}
                                        component={Link}
                                        className="menu-link" to="/kasutajad"
                                    >
                                        KASUTAJAD
                                    </Button>
                                }
                            </>
                        }
                    </Box>
                    {
                        userContext.token &&
                        <>
                            <Typography
                                noWrap
                                component="div"
                                sx={{ mr: 2, display: { md: 'flex' } }}
                            >
                                {userContext.name}
                                </Typography>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar src="/static/images/avatar/2.jpg" />
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleCloseUserMenu && logOut}>
                                        <Typography textAlign="center">Logi välja</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </>
                    }
                </Toolbar>
            </Container>
        </AppBar >
    );
};
export default Header;
