import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
    Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControlLabel, Grid, AppBar, Toolbar, IconButton, Typography, TablePagination, Switch,
} from '@mui/material';
import { Add, Cancel, Close, DeleteForever, Edit, Save } from '@mui/icons-material';
import { DateTime } from 'luxon';
import axios from './CustomAxios';
import { UserContext } from './context/UserContext';
import { toast } from 'react-toastify';
import { SearchInput, LoadingLinearProgress, PageTitle, TableSortingElements } from './components';

function Users() {
    const [userContext, setUserContext] = useContext(UserContext)

    const [currentUser, setCurrentUser] = useState({});
    const [sortOrderDesc, setSortOrderDesc] = useState(true);
    const [sortOrderField, setSortOrderField] = useState('id');
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [countAll, setCountAll] = useState(0);
    const [dense, setDense] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);

    const handleClickOpen = (i) => {
        var tempuser = Object.assign({}, users[i]);
        for (const property in tempuser) {
            if (tempuser[property] === null)
                tempuser[property] = '';
        }
        setCurrentUser(tempuser);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleSave = (keepOpen) => {
        var body = Object.assign({}, currentUser);
        delete body.createdAt;
        delete body.updatedAt;
        if (currentUser.id) {
            return axios(userContext.token, setUserContext).put(process.env.REACT_APP_API_ENDPOINT + 'users/' + currentUser.id, body)
                .then(res => {
                    getAllUsers();
                })
                .then(() => {
                    if (!keepOpen)
                        setOpen(false);
                });
        }
        else {
            return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'users', body)
                .then(res => {
                    getAllUsers();
                })
                .then(() => {
                    if (!keepOpen) {
                        setOpen(false);
                    }
                });
        }
    };

    const handleDelete = () => {
        return axios(userContext.token, setUserContext).delete(process.env.REACT_APP_API_ENDPOINT + 'users/' + currentUser.id)
            .then(res => {
                getAllUsers();
                setDeleteOpen(false);
                setOpen(false);
            });
    };

    const handleChangeSearchKeyword = (value => {
        setPage(0);
        setSearchKeyword(value);
    });

    const handleCreate = () => {
        setCurrentUser(
            {
            }
        );
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSortOrderField = (event) => {
        setSortOrderField(event.target.value);
    };

    const modifyCurrentUser = (e, isNumber, valueProperty = 'value') => {
        var tempuser = Object.assign({}, currentUser);

        // Always modify name into uppercase
        if (e.target.name === 'name')
            e.target[valueProperty] = e.target[valueProperty].toUpperCase();

        if (isNumber)
            tempuser[e.target.name] = parseInt(e.target[valueProperty]);
        else
            tempuser[e.target.name] = e.target[valueProperty];
        setCurrentUser(tempuser);
    };

    const getAllUsers = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        setLoading(true);
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'users?param=' + encodeURIComponent(JSON.stringify({
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            order: [
                [sortOrderField, sortOrderDesc ? 'DESC' : 'ASC']
            ],
            searchKeyword: searchKeyword !== '' ? searchKeyword.trim() : undefined
        })))
            .then(res => {
                setUsers(res.data.rows);
                setCountAll(res.data.count);
                setLoading(false);
            });
    }, [page, rowsPerPage, searchKeyword, sortOrderDesc, sortOrderField, userContext.token, setUserContext]);

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    return (
        <>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
            >
                <DialogTitle>
                    Oled kindel, et soovid kustutada kasutaja {currentUser.id}?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>Ei</Button>
                    <Button
                        autoFocus
                        onClick={() => toast.promise(
                            handleDelete(),
                            {
                                pending: 'Valmistun kustutama',
                                success: 'Kustutatud',
                                error: 'Kustutamisel tekkis tõrge'
                            }
                        )}
                    >Jah</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid item container direction='row' justifyContent='space-between' xs={12}>
                            <Grid item xs={1}>
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={handleClose}
                                    aria-label='close'
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                            <Grid item container direction='row' xs={11} justifyContent='flex-end' spacing={4} alignItems='center'>
                                <Grid item>
                                    <Button
                                        onClick={() => setDeleteOpen(true)}
                                        aria-label='delete'
                                        color='error'
                                        variant='contained'
                                    >
                                        <DeleteForever />&nbsp;Kustuta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleClose}
                                        aria-label='close'
                                        variant='contained'
                                        color='gray'
                                    >
                                        <Cancel />&nbsp;Katkesta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => toast.promise(
                                            handleSave(),
                                            {
                                                pending: 'Salvestan',
                                                success: 'Salvestatud',
                                                error: 'Salvestamisel tekkis tõrge'
                                            }
                                        )}
                                        aria-label='save'
                                        variant='contained'
                                        disabled={
                                            currentUser.name === undefined ||
                                            currentUser.name === '' ||
                                            currentUser.username === undefined ||
                                            currentUser.username === '' || 
                                            (currentUser.id === undefined && (currentUser.password === undefined || currentUser.password === ''))
                                        }
                                        color='success'
                                    >
                                        <Save />&nbsp;Salvesta
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogTitle>
                    {currentUser.id ? 'Kasutaja nr ' + currentUser.id : 'Uus kasutaja'}
                </DialogTitle>
                <DialogContent>
                    <Grid spacing={8} container direction='row'>
                        <Grid item xs={6}>
                            <TextField
                                margin='normal'
                                name='name'
                                value={currentUser.name}
                                onChange={modifyCurrentUser}
                                label='Nimi'
                                fullWidth
                                required
                            />
                            <TextField
                                margin='normal'
                                name='username'
                                value={currentUser.username}
                                onChange={modifyCurrentUser}
                                label='Kasutajanimi'
                                fullWidth
                                required
                            />
                            <TextField
                                margin='normal'
                                name='password'
                                value={currentUser.password ?? ''}
                                onChange={modifyCurrentUser}
                                label='Parool'
                                type='password'
                                fullWidth
                                required={!currentUser.id}
                            />
                            {
                                currentUser.createdAt && currentUser.updatedAt &&
                                <Grid item>
                                    <Typography>Loodud: {DateTime.fromISO(currentUser.createdAt).toFormat('dd.MM.yyyy HH:mm')}</Typography>
                                    <Typography>Muudetud: {DateTime.fromISO(currentUser.updatedAt).toFormat('dd.MM.yyyy HH:mm')}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <LoadingLinearProgress loading={loading} />
            <Grid
                container
                className='user-filter-bar'
                spacing={4}
                style={{ marginTop: 0, marginBottom: '10px' }}
            >
                <PageTitle title='Patsiendid' />
                <TableSortingElements 
                    sortOrderDesc={sortOrderDesc} 
                    setSortOrderDesc={setSortOrderDesc} 
                    sortOrderField={sortOrderField} 
                    handleChangeSortOrderField={handleChangeSortOrderField} 
                    />
                <Grid item xs={4}>
                    <SearchInput event={handleChangeSearchKeyword} />
                </Grid>
                <Grid item xs={1.5}>
                    <FormControlLabel
                        style={{ height: '100%' }}
                        control={
                            <Switch checked={dense} onChange={handleChangeDense} />
                        }
                        label='Kitsas vaade'
                    />
                </Grid>
                <Grid container item xs={1.5} justifyContent='flex-end'>
                    <Button onClick={handleCreate}><Add />&nbsp;Lisa uus</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align='left'>Nimi</TableCell>
                            <TableCell align='left'>Kasutajanimi</TableCell>
                            <TableCell align='left'>Loodud</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            users.map((user, i) => (
                                <TableRow
                                    key={user.id}
                                    className='tableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component='th' scope='row'>{user.id}</TableCell>
                                    <TableCell align='left'>{user.name}</TableCell>
                                    <TableCell align='left'>{user.username}</TableCell>
                                    <TableCell align='left'>{DateTime.fromISO(user.createdAt).toFormat('dd.MM.yyyy HH:mm')}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            edge='start'
                                            color='inherit'
                                            onClick={() => handleClickOpen(i)}
                                            aria-label='open'
                                            className='user-action-button'
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={countAll}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default Users;