import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
    Grid, Button,
    Autocomplete, TextField, Typography, Dialog, AppBar, Toolbar, IconButton, DialogTitle, DialogContent,
    Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { UserContext } from '../context/UserContext';
import axios from '../CustomAxios';
import { DateTime } from 'luxon';
import downloadAsFile from '../helpers/downloadAsFile';
import LoadingOverlay from '../components/LoadingOverlay';
import { Close } from '@mui/icons-material';
import lodash from 'lodash';

function RetailAuditReport() {
    const [userContext, setUserContext] = useContext(UserContext);

    const [institutions, setInstitutions] = useState([]);
    const [selectedInstitutionId, setSelectedInstitutionId] = useState(null);
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [displayLoading, setDisplayLoading] = useState(false);
    const [overviewData, setOverviewData] = useState(null);

    const handleGenerateReport = () => {
        setDisplayLoading(true)
        return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'reports/audit/excel',
            {
                institutionId: selectedPatient === null ? selectedInstitutionId : undefined,
                patientId: selectedPatient?.id,
                startDate: startDate,
                endDate: endDate
            },
            {
                responseType: 'blob'
            }
        )
            .then(res => {
                downloadAsFile('audit.xlsx', res.data);
                setDisplayLoading(false);
            })
            .catch(ex => {
                console.error(ex);
                setDisplayLoading(false);
            });
    };

    const handleShowOverview = () => {
        setDisplayLoading(true)
        return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'reports/audit/month',
            {
                institutionId: selectedPatient === null ? selectedInstitutionId : undefined,
                patientId: selectedPatient?.id,
                startDate: startDate,
                endDate: endDate
            }
        )
            .then(res => {
                let groupedData = lodash.groupBy(res.data, ({ createdAt }) => DateTime.fromISO(createdAt).toFormat('MM.yyyy'));
                setOverviewData(groupedData);
                setDisplayLoading(false);
            })
            .catch(ex => {
                console.error(ex);
                setDisplayLoading(false);
            });
    }

    const getAllPatients = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'patients')
            .then(res => {
                setPatients(res.data.rows);
            });
    }, [userContext.token, setUserContext]);
    const getAllInstitutions = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'institutions')
            .then(res => {
                setInstitutions(res.data.rows);
            });
    }, [userContext.token, setUserContext]);

    useEffect(() => {
        getAllPatients();
        getAllInstitutions();
    }, [getAllPatients, getAllInstitutions]);



    return (
        <>
            <Dialog
                open={overviewData !== null}
                onClose={() => setOverviewData(null)}
                style={
                    {
                        zIndex: 10000,
                        minWidth: '50vw'
                    }
                }
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid item container direction='row' justifyContent='space-between' xs={12}>
                            <Grid item xs={1}>
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={() => setOverviewData(null)}
                                    aria-label='close'
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogTitle>
                    Jaendamise ülevaade
                </DialogTitle>
                <DialogContent>
                    {
                        <Table
                            sx={{ minWidth: '30vw' }}
                            size={'small'}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Kuu</TableCell>
                                    <TableCell>Patsiente</TableCell>
                                    <TableCell align='left'>Jaendamisi</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (overviewData !== null ? Object.entries(overviewData) : []).map((monthData, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                className='tableRow'
                                            >
                                                <TableCell>{monthData[0]}</TableCell>
                                                <TableCell component='th' scope='row'>{new Set(monthData[1].map(packagedTreatment => packagedTreatment.patientId)).size}</TableCell>
                                                <TableCell align='left'>{monthData[1].length}</TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    }
                </DialogContent>
            </Dialog>
            <LoadingOverlay display={displayLoading} />
            <Grid container direction='column' justifyContent='center' alignItems='center'>
                <Grid item style={{ paddingBottom: '20px' }}>
                    <Typography variant='h5'>Jaendamise audit/raport</Typography>
                </Grid>
                <Grid item>
                    <Autocomplete
                        disablePortal
                        required
                        options={institutions}
                        getOptionLabel={(institution) => institution.name}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        value={institutions.filter(x => x.id === selectedInstitutionId)[0] ?? null}
                        sx={{ 'minWidth': 300 }}
                        onChange={(event, newValue) => {
                            if (newValue !== null)
                                setSelectedInstitutionId(newValue?.id);
                            else
                                setSelectedInstitutionId(null);
                        }}
                        renderInput={(params) => <TextField
                            margin='normal' {...params} label="Asutus" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        disablePortal
                        options={selectedInstitutionId !== null ? patients.filter(x => x.institutionId === selectedInstitutionId) : patients}
                        getOptionLabel={(patient) => patient.name !== undefined ? patient.id + ' - ' + patient.name + ' - ' + patient.identificationCode : ''}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        value={patients.filter(x => x.id === selectedPatient?.id)[0] ?? null}
                        sx={{ 'minWidth': 300 }}
                        onChange={(event, newValue) => {
                            if (newValue === null)
                                setSelectedPatient(null);
                            else
                                setSelectedPatient(newValue);
                        }}
                        renderInput={(params) => <TextField
                            margin='normal' {...params} label="Patsient" />}
                    />
                </Grid>
                <Grid item>
                    <DesktopDatePicker
                        label='Pakendamise algus'
                        mask='__.__.____'
                        inputFormat='dd.MM.yyyy'
                        value={startDate}
                        onChange={(e) => { setStartDate(e); }}
                        renderInput={(params) => <TextField
                            sx={{ 'minWidth': 300 }}
                            margin='normal' {...params} />}
                    />
                </Grid>
                <Grid item>
                    <DesktopDatePicker
                        label='Pakendamise lõpp'
                        mask='__.__.____'
                        inputFormat='dd.MM.yyyy'
                        value={endDate}
                        onChange={(e) => { setEndDate(e); }}
                        renderInput={(params) => <TextField
                            sx={{ 'minWidth': 300 }}
                            margin='normal' {...params}
                            helperText={DateTime.fromISO(endDate) < DateTime.fromISO(startDate) ? 'Perioodi lõpp ei saa olla enne algust' : ''}
                            {...params}
                            error={DateTime.fromISO(endDate) < DateTime.fromISO(startDate)}
                        />
                        }
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                onClick={() => handleGenerateReport()}
                                disabled={
                                    DateTime.fromISO(endDate) < DateTime.fromISO(startDate)
                                }
                            >
                                Loo raport
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => handleShowOverview()}
                                disabled={
                                    DateTime.fromISO(endDate) < DateTime.fromISO(startDate)
                                }
                            >
                                Näita ülevaadet
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default RetailAuditReport;