import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./context/UserContext";
import { Button, TextField, Paper, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Login = () => {
    const [error, setError] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [searchParams,] = useSearchParams();

    let navigate = useNavigate();
    useEffect(() => {
        if (userContext !== undefined && userContext.token !== undefined && userContext.token !== null) {
            let returnPath = searchParams.get('return');
            if (returnPath !== null) {
                navigate(returnPath);
            } else {
                navigate('/raviskeemid');
            }
        }
    });

    const handleChange = (event, fun) => {
        fun(event.target.value);
    };
    const submitLogin = e => {
        e.preventDefault();
        setError("");

        const genericErrorMessage = "Midagi läks valesti, palun proovi uuesti!";
        fetch(process.env.REACT_APP_API_ENDPOINT + "login/password", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password }),
        })
            .then(async response => {
                if (!response.ok) {
                    if (response.status === 400) {
                        setError("Mõlemad väljad on kohustuslikud!");
                    } else if (response.status === 401) {
                        setError("Sisestatud kasutajanimi või parool on vale.");
                    } else {
                        setError(genericErrorMessage);
                    }
                } else {
                    const data = await response.json();
                    const decoded = jwtDecode(data.token);
                    setUserContext(oldValues => {
                        return { ...oldValues, token: data.token, ...decoded }
                    });
                    navigate('/raviskeemid');
                }
            })
            .catch(error => {
                setError(genericErrorMessage);
            });
    }
    return (
        <form onSubmit={submitLogin}>
            <Grid className="home-container" container>
                <Grid xl={4} xs={6} id="home-text-container" item alignItems="center">
                    <Paper
                        elevation={2}
                        className="login-background"
                        id="auth-form"
                    >
                        <Grid container direction="column" spacing={2}>

                            <Grid item>
                                <Typography component="h1" variant="h5">
                                    Logi sisse
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="username"
                                    placeholder="Username"
                                    fullWidth
                                    name="username"
                                    variant="outlined"
                                    required
                                    autoFocus
                                    value={username}
                                    onChange={(e) => handleChange(e, setUsername)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    type="password"
                                    placeholder="Parool"
                                    fullWidth
                                    name="password"
                                    variant="outlined"
                                    required
                                    value={password}
                                    onChange={(e) => handleChange(e, setPassword)}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="button-block"
                                    onClick={submitLogin}
                                    onKeyDown={e => e.key === 'Enter' ? submitLogin : ''}
                                >
                                    Logi sisse
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" color="error">
                                    {error}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
        </form>
    )
}

export default Login
