import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
    Button, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControlLabel, Grid, AppBar, Toolbar, IconButton, Typography, TablePagination, Switch,
    Stack
} from '@mui/material';
import { Edit, DeleteForever, Close, Add, Cancel, Save, Done } from '@mui/icons-material';
import axios from './CustomAxios';
import { UserContext } from './context/UserContext';

import { toast } from 'react-toastify';
import { SearchInput, LoadingLinearProgress, PageTitle, TableSortingElements } from './components';

function Drugs() {
    const [userContext, setUserContext] = useContext(UserContext)

    const [currentDrug, setCurrentDrug] = useState({});
    const [sortOrderDesc, setSortOrderDesc] = useState(true);
    const [sortOrderField, setSortOrderField] = useState('id');
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [countAll, setCountAll] = useState(0);
    const [dense, setDense] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(false);

    const [drugs, setDrugs] = useState([])

    const handleClickOpen = (i) => {

        var tempDrug = Object.assign({}, drugs[i]);
        for (const property in tempDrug) {
            if (tempDrug[property] === null)
                tempDrug[property] = '';
        }
        setCurrentDrug(tempDrug);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const handleSave = (keepOpen) => {
        var body = Object.assign({}, currentDrug);
        if (body.name)
            if (currentDrug.id) {
                return axios(userContext.token, setUserContext).put(process.env.REACT_APP_API_ENDPOINT + 'drugs/' + currentDrug.id, body)
                    .then(res => {
                        getAllDrugs();
                    })
                    .then(() => {
                        if (!keepOpen)
                            setOpen(false);
                    });
            }
            else {
                return axios(userContext.token, setUserContext).post(process.env.REACT_APP_API_ENDPOINT + 'drugs', body)
                    .then(res => {
                        getAllDrugs();
                    })
                    .then(() => {
                        if (!keepOpen) {
                            setOpen(false);
                        }
                    });
            }
    };

    const handleDelete = () => {
        return axios(userContext.token, setUserContext).delete(process.env.REACT_APP_API_ENDPOINT + 'drugs/' + currentDrug.id)
            .then(res => {
                getAllDrugs();
                setDeleteOpen(false);
                setOpen(false);
            });
    };

    const handleChangeSearchKeyword = (value => {
        setSearchKeyword(value);
    });

    const handleCreate = () => {
        setCurrentDrug(
            {
            }
        );
        setOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSortOrderField = (event) => {
        setSortOrderField(event.target.value);
    }

    const modifyCurrentDrug = (e, isNumber, valueProperty = 'value') => {
        var tempDrug = Object.assign({}, currentDrug);
        if (isNumber)
            tempDrug[e.target.name] = parseInt(e.target[valueProperty]);
        else
            tempDrug[e.target.name] = e.target[valueProperty];
        setCurrentDrug(tempDrug);
    };

    const getAllDrugs = useCallback(() => {
        if (!userContext.token) {
            return;
        }
        setLoading(true);
        return axios(userContext.token, setUserContext).get(process.env.REACT_APP_API_ENDPOINT + 'drugs?param=' + encodeURIComponent(JSON.stringify({
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            order: [
                [sortOrderField, sortOrderDesc ? 'DESC' : 'ASC']
            ],
            searchKeyword: searchKeyword !== '' ? searchKeyword.trim() : undefined
        })))
            .then(res => {
                setDrugs(res.data.rows);
                setCountAll(res.data.count);
                setLoading(false);
            });
    }, [page, rowsPerPage, searchKeyword, sortOrderDesc, sortOrderField, userContext.token, setUserContext]);

    useEffect(() => {
        getAllDrugs();
    }, [getAllDrugs]);

    return (
        <>
            <Dialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
            >
                <DialogTitle>
                    Oled kindel, et soovid kustutada ravimit {currentDrug.id}?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setDeleteOpen(false)}>Ei</Button>
                    <Button
                        autoFocus
                        onClick={() => toast.promise(
                            handleDelete(),
                            {
                                pending: 'Valmistun kustutama',
                                success: 'Kustutatud',
                                error: 'Kustutamisel tekkis tõrge'
                            }
                        )}
                    >Jah</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid item container direction='row' justifyContent='space-between' xs={12}>
                            <Grid item xs={1}>
                                <IconButton
                                    edge='start'
                                    color='inherit'
                                    onClick={handleClose}
                                    aria-label='close'
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                            <Grid item container direction='row' xs={11} justifyContent='flex-end' spacing={4} alignItems='center'>
                                <Grid item>
                                    <Button
                                        onClick={() => setDeleteOpen(true)}
                                        aria-label='delete'
                                        color='error'
                                        variant='contained'
                                    >
                                        <DeleteForever />&nbsp;Kustuta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleClose}
                                        aria-label='close'
                                        variant='contained'
                                        color='gray'
                                    >
                                        <Cancel />&nbsp;Katkesta
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => toast.promise(
                                            handleSave(),
                                            {
                                                pending: 'Salvestan',
                                                success: 'Salvestatud',
                                                error: 'Salvestamisel tekkis tõrge'
                                            }
                                        )}
                                        aria-label='save'
                                        variant='contained'
                                        color='success'
                                    >
                                        <Save />&nbsp;Salvesta
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogTitle>
                    {currentDrug.id ? 'Ravim ' + currentDrug.name : 'Uus ravim'}
                </DialogTitle>
                <DialogContent>
                    <Grid spacing={8} container direction='row'>
                        <Grid item xs={6}>
                            <TextField
                                margin='normal'
                                name='name'
                                value={currentDrug.name}
                                onChange={modifyCurrentDrug}
                                label='Nimi'
                                fullWidth
                                required={true}
                            />
                            <TextField
                                margin='normal'
                                name='code'
                                value={currentDrug.code}
                                onChange={modifyCurrentDrug}
                                label='Kood'
                                fullWidth
                            />
                            <TextField
                                margin='normal'
                                name='form'
                                value={currentDrug.form}
                                onChange={modifyCurrentDrug}
                                label='Ravimvorm'
                                fullWidth
                            />
                            <TextField
                                margin='normal'
                                name='shape'
                                value={currentDrug.shape}
                                onChange={modifyCurrentDrug}
                                label='Kuju'
                                fullWidth
                            />
                            <TextField
                                margin='normal'
                                name='color'
                                value={currentDrug.color}
                                onChange={modifyCurrentDrug}
                                label='Värv'
                                fullWidth
                            />
                            <TextField
                                margin='normal'
                                name='activeIngredient'
                                value={currentDrug.activeIngredient}
                                onChange={modifyCurrentDrug}
                                label='Toimeaine'
                                fullWidth
                            />
                            <TextField
                                margin='normal'
                                name='description'
                                value={currentDrug.description}
                                onChange={modifyCurrentDrug}
                                label='Poolitusjoon'
                                fullWidth
                            />
                            <Stack direction='row' spacing={1} alignItems='center' style={{ paddingBottom: '20px' }}>
                                <Typography>Labiilne ravimvorm</Typography>
                                <Close />
                                <Switch
                                    checked={currentDrug.isLabile ?? false}
                                    name='isLabile'
                                    onChange={(e) => modifyCurrentDrug(e, false, 'checked')}
                                />
                                <Done />
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <LoadingLinearProgress loading={loading} />
            <Grid
                container
                spacing={4}
                style={{ marginTop: 0, marginBottom: '10px' }}
            >
                <PageTitle title='Ravimid' />
                <TableSortingElements 
                    sortOrderDesc={sortOrderDesc} 
                    setSortOrderDesc={setSortOrderDesc} 
                    sortOrderField={sortOrderField} 
                    handleChangeSortOrderField={handleChangeSortOrderField} 
                    />
                <Grid item xs={4}>
                    <SearchInput event={handleChangeSearchKeyword} />
                </Grid>
                <Grid item xs={1.5}>
                    <FormControlLabel
                        style={{ height: '100%' }}
                        control={
                            <Switch checked={dense} onChange={handleChangeDense} />
                        }
                        label='Kitsas vaade'
                    />
                </Grid>
                <Grid container item xs={1.5} justifyContent='flex-end'>
                    <Button onClick={handleCreate}><Add />&nbsp;Lisa uus</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align='right'>Ravim</TableCell>
                            <TableCell align='right'>Kood</TableCell>
                            <TableCell align='right'>Ravimvorm</TableCell>
                            <TableCell align='right'>Värv</TableCell>
                            <TableCell align='right'>Kuju</TableCell>
                            <TableCell align='right'>Toimeaine</TableCell>
                            <TableCell align='right'>Poolitusjoon</TableCell>
                            <TableCell align='right'></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            drugs.map((drug, i) => (
                                <TableRow
                                    key={drug.id}
                                    className='tableRow'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component='th' scope='row'>{drug.id}</TableCell>
                                    <TableCell align='right'>{drug.name}</TableCell>
                                    <TableCell align='right'>{drug.code}</TableCell>
                                    <TableCell align='right'>{drug.form}</TableCell>
                                    <TableCell align='right'>{drug.color}</TableCell>
                                    <TableCell align='right'>{drug.shape}</TableCell>
                                    <TableCell align='right'>{drug.activeIngredient}</TableCell>
                                    <TableCell align='right'>{drug.description}</TableCell>
                                    <TableCell align='center'>
                                        <IconButton
                                            edge='start'
                                            color='inherit'
                                            onClick={() => handleClickOpen(i)}
                                            aria-label='open'
                                            className='treatment-action-button'
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={countAll}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
}

export default Drugs;