import React, { useState } from 'react';
import {
    Grid, Button
} from '@mui/material';
import PatientReport from './Reports/PatientReport';
import TreatmentReport from './Reports/TreatmentReport';
import DrugReport from './Reports/DrugReport';
import RetailAuditReport from './Reports/RetailAuditReport';
import { PageTitle } from './components';

function Reporting() {
    const [selectedReport, setSelectedReport] = useState('');

    return (
        <>
            <Grid
                container
                spacing={4}
                style={{ marginTop: 0, marginBottom: '10px' }}
            >
                <PageTitle title='Raportid' />
                <Grid item container justifyContent='center' alignItems='center' xs={10}>
                    <Grid item xs={2.5}>
                        <Button variant={selectedReport === 'patient' ? 'contained' : 'outlined'} onClick={() => setSelectedReport('patient')}>Patsiendi raport</Button>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Button variant={selectedReport === 'treatment' ? 'contained' : 'outlined'} onClick={() => setSelectedReport('treatment')}>Skeemide raport</Button>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Button variant={selectedReport === 'drug' ? 'contained' : 'outlined'} onClick={() => setSelectedReport('drug')}>Ravimite raport</Button>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Button variant={selectedReport === 'retailAudit' ? 'contained' : 'outlined'} onClick={() => setSelectedReport('retailAudit')}>Jaendamise raport</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' justifyContent='center' alignItems='center'>
                {
                    selectedReport === 'patient' &&
                    <PatientReport />
                }
                {
                    selectedReport === 'treatment' &&
                    <TreatmentReport />
                }
                {
                    selectedReport === 'drug' &&
                    <DrugReport />
                }
                {
                    selectedReport === 'retailAudit' &&
                    <RetailAuditReport />
                }
            </Grid>
        </>
    );
}

export default Reporting;